
import { computed, defineComponent } from "vue";

import { AppActionTypes, AppMutationTypes, useStore } from "@/store";
import { loadData } from "@/helpers";
import { CancelSaveOrderModel, SubscriptionType } from "@/models";
import swal from "sweetalert";
import { hasErrors } from "@elite/validation";
import { useRouter } from "vue-router";
import { routeNames } from "@/router/routeNames";

export default defineComponent({
    props: {
        subscriptionId: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const store = useStore();
        const router = useRouter();
        const { getters, state, dispatch } = store;

        const subscription = computed(
            () => state.subscriptionDetails[props.subscriptionId]
        );

        loadData(store, async () => {
            await dispatch(AppActionTypes.getSubscriptionDetail, {
                type: props.type as SubscriptionType,
                id: props.subscriptionId
            });
        });

        const noButtonClick = async (): Promise<void> => {
            const response = await dispatch(AppActionTypes.cancelSubscription, {
                type: props.type as SubscriptionType,
                id: props.subscriptionId || ""
            });

            if (!hasErrors(response)) {
                await swal({
                    title: "Cancelled Subscription",
                    content: {
                        element: "div",
                        attributes: {
                            className: "text-start",
                            innerHTML: `
                                <p>Your cancellation is complete. It may take 10-15 minutes for the subscription status to be updated.</p>
                                <br/>
                                <p>Please note, if you have other subscriptions that you would like to cancel, you must process those cancellations individually. When signed in to <a href="https://members.espired.com">members.espired.com</a>, simply click on "Manage My Account" where you can see the status of your subscriptions. If you do not take action to cancel them, they will remain active.</p>
                            `
                        }
                    },
                    icon: "success"
                });
            } else {
                await swal({
                    title: "Error Cancelling Subscription",
                    text: response.errors[""].join(" "),
                    icon: "error"
                });
            }
            // we need to reset the subscription and the subscriptionDetail state for the request to be called in the api when
            // AppActionTypes.getSubscriptions is dispatched in AccountView
            // AppActionTypes.getSubscriptionDetail is dispatched in SubscriptionView
            store.commit(AppMutationTypes.setSubscriptions, []);
            store.commit(AppMutationTypes.removeSubscriptionDetail, {
                id: props.subscriptionId
            });
            await router.push({ name: routeNames.account });
        };

        const yesButtonClick = async (): Promise<void> => {
            const cancelSaveOrderModel: CancelSaveOrderModel = {
                billingCustomerNumber:
                    subscription.value?.billingAddress?.customerNumber,
                shippingCustomerNumber:
                    subscription.value?.shippingAddress?.customerNumber,
                promotionCode:
                    subscription.value?.cancellationOffer?.promotionCode,
                electronicPaymentId: subscription.value?.paymentInfo?.id,
                campaignCode:
                    subscription.value?.cancellationOffer?.campaignCode,
                cancelSaveOfferId: subscription.value?.cancellationOffer?.id
            };

            const response = await dispatch(
                AppActionTypes.createCancelSaveOrder,
                {
                    type: props.type,
                    id: props.subscriptionId || "",
                    model: cancelSaveOrderModel
                }
            );

            if (!hasErrors(response)) {
                await swal({
                    title: "Processing Subscription",
                    text: "Congratulations! You made a great choice! It may take up to 24 hours for your subscription to update in your account.",
                    icon: "success"
                });
            } else {
                await swal({
                    title: "Error Processing Subscription",
                    text: response.errors[""].join(" "),
                    icon: "error"
                });
            }
            // we need to reset the subscription and the subscriptionDetail state for the request to be called in the api when
            // AppActionTypes.getSubscriptions is dispatched in AccountView
            // AppActionTypes.getSubscriptionDetail is dispatched in SubscriptionView
            store.commit(AppMutationTypes.setSubscriptions, []);
            store.commit(AppMutationTypes.removeSubscriptionDetail, {
                id: props.subscriptionId
            });
            await router.push({ name: routeNames.account });
        };

        return {
            noButtonClick,
            yesButtonClick,
            loading: computed(() => getters.loading),
            cancellationOffer: computed(
                () => subscription.value.cancellationOffer
            ),
            cancellationOfferImages: computed(
                () =>
                    subscription.value.cancellationOffer
                        ?.cancellationOfferImages || []
            )
        };
    }
});
